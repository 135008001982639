import React, {useState} from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import {getperson, start} from '../actions/card'

const SING = styled.img`
  height: 200px;
  float: left;
  margin-left: 10px;
  cursor: pointer;
`;
const SONG = styled.div`
`;
const FLIP = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &:hover {
    transform: rotateY(180deg);
  }
`;
const FRONT = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;
const BACK = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
`;

export const Cast = ({ folk }) => {
  const dispatch = useDispatch()
  const cast  = useSelector(state => state.post.cast)
  const crew  = useSelector(state => state.post.crew)
  const lang  = useSelector(state => state.post.lang)
  const name1 = useSelector(state => state.post.name1)
  const name2 = useSelector(state => state.post.name2)
  const year1 = useSelector(state => state.post.year1)
  const year2 = useSelector(state => state.post.year2)

  const [Code, setCode] = useState(0)
  const [Flag, setFlag] = useState(0)

  let name, perf, post, pers

  if (folk === 1) { 
    post = cast 
  }
  if (folk === 2) { 
    post = crew 
  }

  const imageClick = (code, numb) => {
    if (numb === 1) {
      dispatch(getperson(code))
    }
    if (numb === 0) {
      dispatch(start(0, 'Cast'))
    }
    setCode(code) 
    setFlag(numb)
  }

  if (lang === 0) {
    name = 3
    perf = 4
    pers = name2
  }
  if (lang === 1) {
    name = 1
    perf = 2
    pers = name1
  }

  if (Flag === 0) {
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <img
          className={className}
          style={{ ...style, display: "block", width: "36px", height: "36px", right: "0px", zIndex:"1", border: "1px solid white", background: "white"}}
          onClick={onClick}
          src={"/Users/0/rt.png"}
          alt="up"
        />
      );
    }
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <img
          className={className}
          style={{ ...style, display: "block", width: "36px", height: "36px", left: "2px", zIndex:"1", border: "1px solid white", background: "white"}}
          onClick={onClick}
          src={"/Users/0/lt.png"}
          alt="dn"
        />
      );
    }
    var settings = {
      infinite: true,
      slidesToShow: 4,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }
    return (
      <div>
        <Slider {...settings}>
          {post.map(poster => (
            
            <label key = {poster[0]}>
            <div id ="crew">
              <div style={{ height: "19%"}}>{poster[name]}</div>
                <FLIP>
                <FRONT>
                  <img
                    className="image"
                    src={'http://kshisa.ru/images/1/'+ poster[0] + 'p1.jpg'}
                    alt= ''
                  />                
                </FRONT>
                <BACK>
                <input type = "image"
                  className = "image"
                        src = {'http://kshisa.ru/images/1/'+ poster[0] + poster[5]}
                        alt = ''
                    onClick = {() => imageClick(poster[0], 1)}
                />
                </BACK>
                </FLIP> 
              <div style={{ margin: "4px"} }>{poster[perf]}</div>
            </div>
            </label>
          ))}
        </Slider>
      </div>
    )
  }
  if (Flag === 1) {
    const click = () => imageClick(Code, 0)
    let life
    if (year2 && year2 > 0) {
      life = year2 - year1
    }
    else {
      life = new Date().getFullYear() - year1
    }
    return (
      <div>
        <SING
          src = {'http://kshisa.ru/images/1/'+ Code + 'p2.jpg'}
          alt = ''
          onClick={click}
        />
        <SONG>{pers}</SONG>
        <SONG>{year1} --- {year2}</SONG>
        <SONG>{life}</SONG>
      </div>
    )
  }
}
export default (Cast)