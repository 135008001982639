import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import {list, timer} from '../actions/card'

const Line = styled.div`
  color: azure;
  height: 68px;
  margin-left: 222px;
  margin-right: 212px;
`;
const H4 = styled.h4`
  font-family: 'BebasNeueRegular', Arial, Helvetica, sans-serif;
  padding-left: 27px;
  color: azure;
  margin: 1px;
  float: left;
  width: 100%;
  font-weight: 100;
`;
const BUTT = styled.button`
  margin-left: 54px;
  cursor: pointer;
  font-size: 16px;
`;

export const Span = ({socket}) => {
  const dispatch = useDispatch()
  const stam  = useSelector(state => state.post.stam)
  const hall  = useSelector(state => state.post.hall)
  const title = useSelector(state => state.post.title)
  const trig  = useSelector(state => state.post.list)
  let time
  const dura = title[2]
  const [date0, setdate0] = useState(new Date())
  const [date1, setdate1] = useState(new Date())
  const [date2, setdate2] = useState(0)
  if (trig === 0) {
    if (title.length > 0) {
      time = dura
    }
    else {
      time = 0
    }
  }
  else {
    time = Math.floor(date2/60)
  }
  socket.on('contrlsResponse', (data) => {
    if (data.text === 'p'){
      dispatch(list(1))
    }
    if (data.text === 's'){
      dispatch(list(2))
    }
  })

  useEffect(()=>{
    if (trig === 1) {
      const tick = () => {
        const d = new Date()
        const hours = (time - time%60)/60
        const min = time%60
        d.setHours(d.getHours() + hours)
        d.setMinutes(d.getMinutes() + min)
        setdate0 (new Date())
        setdate1 (d)
        setdate2 (date2 - 1)
      }
      const timerID = setInterval( () => tick(), 1000 )
      return () => {
        clearInterval(timerID)
      }
    }
    else if (trig === 0) {
      setdate2 (dura*60)
    }
    else if (trig === 2) {
      dispatch(timer(stam, title, date2, hall))
    }
  },[time, date2, trig, dura, stam, title, hall, dispatch])
  
  return (
    <Line>
      <H4>
        {date0.toLocaleTimeString()}
      </H4>
      <BUTT>
        {(( time-time%60 )/60 < 10  ? "0" : "" ) + ( time-time%60 )/60 + ':'+ 
          ( time%60 < 10 ? "0" : "" ) + time%60 }
      </BUTT>
      <H4>
        {date1.toLocaleTimeString()}
      </H4>
    </Line>
  )
}
export default (Span)