import React from "react";
import { useDispatch, useSelector } from "react-redux"
import {start, exit, tapes} from '../actions/card'

export const Head = ({socket}) => {
  const dispatch = useDispatch()
  const login = useSelector(state => state.post.login)
  const text  = useSelector(state => state.post.text)
  const lang  = useSelector(state => state.post.lang)
  const user  = useSelector(state => state.post.usernum)
  const code = useSelector(state => state.post.user)

  const final = () => {
    dispatch(exit())
    dispatch(tapes(6, '0f100p1.jpg', '0f100'))
    socket.emit('exit', [user, code, socket.id])
  }

  let ring
  const head = {
    height: "69px",
    border: "groove white",
    backgroundColor: "white"
  }
  const icon = {
    position: 'relative',
    width: '67px',
    height: '67px',
    margin: 'auto',
    zIndex: '3',
    boxShadow: '0 0 0 10px rgb(255 255 255 / 20%), 0 0 25px 2px rgb(0 0 0 / 40%), inset 0 0 0 15px rgb(255 255 155 / 40%)',
    borderRadius: '999px',
    right: '-18%',
    float: 'left'
  }  
  const buts = {
    width: "42px",
    height: "42px",
    float: "left",
    padding: "12.3px",
    zIndex: "3"
  }
  const news = {
    fontSize: "23px",
    float: "left",
    width: "39%",
    paddingTop: "9px",
  }  
  const logo = {
    width: "177px",
    position: 'relative',
  }
  const uman = {
    float: "left"
  }
  if (!user) {
    let advt
    if (lang === 1){
      advt = ['Type password', 'Fill the form']
    }
    if (lang === 0){
      advt = ['Введите пароль', 'Заполните форму']
    }
    if (login === 0 || login === 4) {
      ring =  <input type    = "image" 
                     name    = "avat"
                     src     = {'/Users/0/login.png' }
                     onClick = {() => dispatch(start(1, advt[0]))}
                     style   = {buts}
                     alt     = 'butt'
              /> 
    }
    if (login === 1) {
      ring =  <input type    = "image"
                     name    = "avat"
                     src     = {'/Users/0/join.png' }
                     onClick = {() => dispatch(start(2, advt[1]))}
                     style   = {buts}
                     alt     = 'butt'
              /> 
    }
    if (login === 2 || login === 3) {
      ring =  <input type    = "image"
                     name    = "avat"
                     src     = {'/Users/0/glaz.png' }
                     onClick = {() => dispatch(start(4, ''))}
                     style   = {buts}
                     alt     = 'butt'
              /> 
    }
  }
  else {
    ring =  <input type    = "image"
                   name    = "avat"
                   src     = {'/Users/0/exit.png' }
                   onClick = {() => final()}
                   style   = {buts}
                   alt     = 'butt'
            /> 
  }

  return (
    <header style = {head}>
      <div style = {uman}>
      <iframe src="https://yoomoney.ru/quickpay/fundraise/button?billNumber=15UU8CHK52D.241018&"
       width="330" height="50" allowtransparency="true" title="umoney">
      </iframe>
     </div> 
      <div style = {news}>
        { text }
      </div>
      <img style = {logo}
        src = {'/Users/0/gorisond.png'}
        alt = 'logo'
      />
      <div style = {icon}>
        {ring}
      </div>
      <div className = "Puls"></div>
      <div className = "Puls2"></div>
    </header>
  )
}

export default (Head)