export const START = 'START'
export const LOGON = 'LOGON'
export const LOGIN = 'LOGIN'
export const GET_FILMDATA = 'GET_FILMDATA'
export const PEOPLE = 'PEOPLE'
export const FILMS = 'FILMS'
export const PROP = 'PROP'
export const CHAT = 'CHAT'
export const MENU = 'MENU'
export const LIST = 'LIST'
export const GET_TAPE = 'GET_TAPE'
export const LOVE = 'LOVE'
export const UPD_PLAC = 'UPD_PLAC'
export const YEAR0 = 'YEAR0'
export const FILEDATA = 'FILEDATA'
export const PIC = 'PIC'
export const GET_PICTS = 'GET_PICTS'
export const FRIEND = 'FRIEND'
export const TEXT = 'TEXT'
export const GET_USER = 'GET_USER'
export const GET_PERSON = 'GET_PERSON'
export const GET_FIND = 'GET_FIND'
export const MODE = 'MODE'
export const LANG = 'LANG'
export const UPD_COUNT = 'UPD_COUNT'
export const UPD_COUNTR = 'UPD_COUNTR'
export const GET_NEXT = 'GET_NEXT'
export const GET_NEXTR = 'GET_NEXTR'
export const COLL = 'COLL'
export const HALL = 'HALL'
export const NEWU = 'NEWU'
export const NUMB = 'NUMB'
export const ADVT = 'ADVT'
export const SAMP = 'SAMP'
export const TAPE = 'TAPE'
export const EXIT = 'EXIT'