import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {newu, pic, del, file, text} from '../actions/card'

export const Self = ({socket}) => {
  const dispatch = useDispatch()
  const entr = useSelector(state => state.post.entr)
  const code = useSelector(state => state.post.user)
  const show = useSelector(state => state.post.showPik)
  const fots = useSelector(state => state.post.picts)
  const user = useSelector(state => state.post.usernum)
  let   name = useSelector(state => state.post.name)
  const birh = useSelector(state => state.post.birth)
  const bill = useSelector(state => state.post.bill)
  const stam = useSelector(state => state.post.stam)
  const about = useSelector(state => state.post.about)

  if (entr === 1) {
    socket.emit('newUser', [user, code, socket.id])
    dispatch(newu())
  }

  const [File, setFile] = useState("")
  const [Lett, setLett] = useState("")
  const [Mode, setMode] = useState(0)
  const [Text, setText] = useState(about)

  const head = {
    float: 'left',
    fontSize: '24px',
    margin: '5px 42px'
  }
  const scrn = {
    background: 'url(/Users/0/tv.png) no-repeat',
    backgroundSize: '100% 99%',
    height: '291px'
  }
  const post = {
    float: 'left',
    width: '167px',
    marginLeft: '20px',
    marginTop: '15px',
    cursor: 'pointer'
  }
  const pics = {
    float: 'left',
    width: '360px',
    margin: '15px 10px 4px 22px',
    overflow: 'hidden',
    height: '220px'
  }
  const imgn = {
    float: 'left',
    width: '170px',
    height: '102px',
    overflow: 'hidden',    
    border: 'groove white',
  }
  const kads = {
    width: '332px',
    marginLeft: '10px',
    height: '211px'
  }
  const addr = {
    padding: '4px 5px',
    fontSize: '16px',
    float: 'left',
    margin: '4px',
    width: '81%',
    textAlign: 'center',
    border: 'groove white',
    height: '22px'
  }
  const inpt = {
    float: 'left',
    margin: '3px',
    height: '28px',
    width: '28px',
    border: 'groove white',
    padding: '2.2px',
    background: 'white',
    cursor: 'pointer'
  }
   const inpt2 = {
    margin: '3px',
    height: '28px',
    width: '28px',
    border: 'groove white',
    padding: '2.2px',
    background: 'white',
    cursor: 'pointer'
  }
  const midl = {
    width: '100%',
    height: '120px',
    paddingTop: '10px'
  }
  const panl = {
    width: '100%',
    height: '67px'
  }
  const butn = {
    width: '58px',
    marginLeft: '4px',
    marginTop: '7px',
    marginBottom: '4px'
  }
  const polr = {
    position: 'relative',
    width: '100%',
    color: 'black',
    fontSize: '12px',
    marginTop: '4px',
    background: 'url(/Users/0/tv.png) no-repeat',
    height: '300px',
    backgroundSize: '100%95%'
  }
  const innr = {
    width: '90%',
    margin: 'auto',
    top: '40px'
  }
  const bigf = {
    border: 'groove white',
  }
  
  const sendText = () => {
    dispatch(text(stam, '', Text, '', 5))
  }
  const getPik = (n) => {
    dispatch(pic(n))
    setMode(0)
  }
  const delPik = (n) => {
    console.log(n)
    dispatch(del(fots[n - 1][1], user))
    setLett("")
  }
  const getFile = (e) => {
    setMode(2)
    setFile(e.target.files[0])
    setLett(e.target.files[0].name)
  }
  const getFile2 = (e) => {
    setMode(3)
    setFile(e.target.files[0])
    setLett('')
  }  
  const type = (lett) => {
    if (Mode === 0) {
       setMode(1) 
    }
    setLett(lett)
  }
  const send = (e) => {
    e.preventDefault()
    if(Mode === 2) {
      const data = new FormData()
      data.append("file", File);
      dispatch(file(data, user, show, Lett))
      setMode(0)
    }
    else if(Mode === 3) {
      const data = new FormData()
      data.append("file", File);
      dispatch(file(data, user, 0, ''))
      setMode(0)
    }
    else {
      dispatch(text(stam, '', Lett, '', 1))
      name = Lett
      setMode(0) 
    }
  }
  let picts, love, buts, line
  
  const foto =  <label>
                <input type = "file" 
                       name = "file"
                   onChange = {getFile2} 
                   required
                />
                <img style = {post}
                       src = {'/Users/' + code + 'p2.jpg'} 
                       alt = ''>
                </img>
              </label>

  if (show === 0) {
    if (Mode === 0) {
      line = name
    }
    else {
      line = Lett
    }
    picts = <div>{fots.map(imag => (
              <label key = {imag[1]}>
                <div style = {imgn}>
                  <input type = "image"
                      onClick = {() => getPik(imag[0])}
                          src = {'Users/' + user + '/' + imag[1] + imag[2]}
                          alt = {imag[1]}
                  ></input>
                </div>
                </label>
            ))}</div>
  }          
  if (show !== 0) { 
    if (fots[show - 1][2] === 'p2.jpg') {
      if (Mode === 0) {
        line = fots[show - 1][4]
      }
      else {
        line = Lett
      }      
      love = <>
              <input type = "image" 
                      src = '/Users/0/del.png'
                  onClick = {() => delPik(show)}
                      alt = ''
              ></input>
             </>   
    }
    if (fots[show - 1][2] === '.png') {
      line = Lett
      love = <>
               <label>
                <input type = "file" 
                       name = "file" 
                   onChange = {getFile} 
                   required
                />
                <img style = {inpt2}
                       src = '/Users/0/up.png' 
                       alt = ''></img>
              </label>
             </>
      }
    picts = <div style = {bigf}>
              <input type = "image"
                    style = {kads} 
                  onClick = {() => getPik(0)}
                      src = {'Users/' + user + '/' + fots[show - 1][1] + fots[show - 1][3]}
                      alt = ''
              ></input>
            </div> 
  }
  const form =  <>
                 <input style = {inpt}
                         type = "image"
                          src = '/Users/0/del.png'
                          alt = ''
                      onClick = { () => type("") } 
                />
                <form onSubmit = { send }>
                 <input style = {addr}
                         type = "text"
                        value = {line}
                          alt = ''
                     onChange = {e => type(e.target.value)}
                />
                 <input style = {inpt}
                        type  = "image"
                          src = '/Users/0/rt.png'
                          alt = ''
                 />
              </form>
              </> 
  const old = new Date().getFullYear() - birh
  if (bill) {
    buts = <div style = {midl}>
              <div style = {panl}>
                {bill.map(butt => (
                    <img style = {butn}
                           src = {'/Users/0/'+ butt +'.png'}
                           alt = ''
                    />
                ))} 
              </div>
           </div>
  }
  const plac = <div>
                 <div style = {head}>About Myself</div>
                 <input style = {inpt}
                         type = "image"
                          src = '/Users/0/rt.png'
                          alt = ''
                      onClick = { () => sendText("") } 
                 />
                 <textarea name  = "" 
                            cols = "65" 
                            rows = "15"  
                           value = {Text}
                        onChange = {e => setText(e.target.value)}
                     ></textarea>
                </div>
                
  return (
    <><div style = {scrn}>
        {foto}
        <div style = {pics}>
          {picts}
        </div>
        {love}
      </div>
      {form}
      <button>{old}</button>
      {buts}
      <div style = {polr}>
        <div style = {innr}>
          {plac}
        </div>
      </div>
    </>
  )
}
export default (Self)