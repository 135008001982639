import React from "react";
import {useSelector} from 'react-redux'

export const Paus = () => {
  const film = useSelector(state => state.post.hall)
  const title = useSelector(state => state.post.title)
  if (film.length > 0){
    const base = film.match(/\d+/)
    const post =  <img src = {'http://kshisa.ru/images/' + base + '/' + film + 'p2.jpg'}
               alt = ''
          />
    return (
      <>
      {post}
      <hr></hr>
      {title[0]}
      <hr></hr>
      {title[1]}
      <hr></hr>
      {base}
      </>
    )
  }
}
export default (Paus)