import {
  START,
  LOGON,
  GET_FILMDATA,
  PEOPLE,
  FILMS,
  PROP,
  CHAT,
  LIST,
  MENU,
  GET_TAPE,
  LOVE,
  UPD_PLAC,
  YEAR0,
  FILEDATA,
  PIC,
  GET_PICTS,
  FRIEND,
  TEXT,
  GET_USER,
  GET_PERSON,
  GET_FIND,
  MODE,
  LANG,
  LOGIN,
  UPD_COUNT,
  UPD_COUNTR,
  GET_NEXT,
  GET_NEXTR,
  COLL,
  HALL,
  NEWU,
  NUMB,
  ADVT,
  SAMP,
  TAPE,
	EXIT
} from '../constants'

const initialState = {
  text:     '',
  login:    4,
  logon:    1,
  dnside:   4,
  user:     '',
  stam:     '',
  title:    [],
  numb:     0,
  Pass:     '',
  swch:     '0f100p1.jpg',
  users:    [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  frend:    [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  temp:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  film:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  post:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  info:     '',
  butsc:    [],
  butsg:    [],
  butsp:    [], 
  placard:  0, 
  love:     '',
  usernum:  '',
  year0:    0,
  lang:     0,
  side:     0,
  coun:     0,
  awar:     [],
  stud:     [],
  song:     [],
  bill:     [],
  man:      0,
  pers:     '',
  code:     '',
  birth:    '',
  about:    '',
  picts:    [],
  showPik:  0,
  person:   [],
  thepers:  '',
  cast:     [],
  crew:     [],
  name1:    '',
  name2:    '',
  year1:    '',
  year2:    '',
  find:     [],
  mode:     0,
  list:     0,
  name:     '',
  fileData: '',
  fileName: '',
  avat:     [[ 1,  '1f200224092722', '' ],
             [ 2,  '1f200225202045', '' ],
             [ 3,  '1f200225224432', '' ],
             [ 4,  '1f200227210557', '' ],
             [ 5,  '1f200227211103', '' ],
             [ 6,  '1f200301092039', '' ],
             [ 7,  '1f200301114207', '' ],
             [ 8,  '1f200301181324', '' ],
             [ 9,  '1f200301221612', '' ],
             [ 10, '1f200301231130', '' ],
             [ 11, '1f200301232453', '' ],
             [ 12, '1f200305124912', '' ],
             [ 13, '1f200309164023', '' ],
             [ 14, '1f200420114028', '' ],
             [ 15, '1f200430105625', '' ],
             [ 16, '1f200430124359', '' ]],
  trigger:  0,
  triggerr: 0,
  count:    1,
  countr:   1,
  numbb:    8, 
  numbs:    5,
  numba:    0,
  tape:     '0f100',
  hall:     [],
  entr:     0,
  chat:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  menu: [],
  time: ''
}
export default function Post (state = initialState, action) {
  switch (action.type) {
    case START:
      return {
        ...state,
        login: action.login,
        text:  action.text
      }
    case EXIT:
      return {
        ...state,
        login: 4,
        logon: 1,
        swch:  '0f100p1.jpg',
        tape:  '0f100',
        usernum: '',
        user:    '',
        name:    '',
        stam:    '',
        birth:   '',
        about:   '',
        bill:    '',
        frend:   '',
        picts:   '',
        text:    '',
        thepers: '',
        entr:    0,
        film:    [[ '', 'blank', '/Users/0/', 'p1.png' ],
                  [ '', 'blank', '/Users/0/', 'p1.png' ],
                  [ '', 'blank', '/Users/0/', 'p1.png' ],
                  [ '', 'blank', '/Users/0/', 'p1.png' ],
                  [ '', 'blank', '/Users/0/', 'p1.png' ]]
      }
    case TAPE:
      return {
        ...state,
        temp: action.tape,
        swch: '0f100p1.jpg'
      }
    case LOGON:
      return {
        ...state,
        login: 1,
        logon: 1,
        text:  action.text
      }
    case GET_FILMDATA:
      return {
        ...state,
        info:  action.card[0],
        base:  action.card[7],
        butsc: action.card[1],
        butsg: action.card[2],
        butsp: action.card[10],
        cast:  action.card[3],       
        crew:  action.card[12],
        love:  action.card[4],
        text:  action.card[5],
        side:  action.card[6],
        song:  action.card[9],
        awar:  action.card[11],
        stud:  action.card[13],
        dnside: action.side,
        placard: 0,
        logon: 1,
        mode: 0
      }
      case GET_USER:
        return {
          ...state,
          login:   4,
          logon:   0,
          swch:    'swich.png',
          usernum: action.user[0],
          user:    action.user[1],
          name:    action.user[2],
          stam:    action.user[0],
          birth:   action.user[3],
          about:   action.user[4],
          bill:    action.bill,
          temp:    action.frnd,
          frend:   action.frnd,
          picts:   action.pict,
          placard: 0,
          text:    action.text,
          film:    action.film,
          thepers: action.user[0],
          menu:    action.menu,
          entr:    1
        }
    case ADVT:
      return {
        ...state,
        text: action.text
      }
    case GET_TAPE:
      return {
        ...state,
        swch: action.rank,
        temp: action.post,
        post: action.post,
        tape: action.tape
      }
    case PEOPLE:
      return {
        ...state,
        man:     action.payload,
        dnside:  action.side,
        person:  action.person,
        bill:    action.bill,
        picts:   action.pict,
        thepers: action.pers,
        text:    action.text,
        logon: 3
      }    
    case FILMS:
      return {
        ...state,
        swch: 'film.jpg',
        pers:  action.film,
        temp:  action.post,
        logon:  1,
        flag:   0
      }
    case PROP:
      return {
        ...state,
        swch:  'frnd.png',
        temp:   action.users,
        picts:  action.picts,
        bill:   action.bill,
        person: action.person,
        film:   action.frnd,
        frend:  action.frnd,
        logon:  3
      }
    case CHAT:
      return {
        ...state,
        swch: 'kino.png',
        film: action.film,
        hall: [],
        login: 3,
        logon: 4
      }
    case LIST:
      return {
        ...state,
        list: action.trig
      }
    case MENU:
      return {
        ...state,
        swch: 'swich.png',
        temp: action.menu
      }
    case LOVE:
      return {
        ...state,
        side:    action.side,
        usernum: action.usern,
        temp:    action.prop,
        swch:    action.swch,
        chek:    action.numb,
        film:    action.love,
        love:    action.blue
      }
    case SAMP:
      console.log(action.film)
      return {
        ...state,
        side:    action.side,
        swch:    action.swch,
        chek:    action.numb,
        love:    action.blue,
        film:    action.film
      }
    case UPD_PLAC:
      return {
        ...state,
        placard: action.placard,
        coun: action.coun
    }
    case YEAR0:
      return {
        ...state,
        year0: action.year0
    }
    case FILEDATA:
      return{
        ...state,
        fileData: action.fileData,
        fileName: action.name,
        mode: 1
      }
    case PIC:
      return{
        ...state,
        showPik: action.pic,
        dnfind: 1
      } 
    case GET_PICTS:
      return{
        ...state,
        picts: action.picts,
        user: action.user
      }
    case FRIEND:
      return {
        ...state,
        film: action.friends[0],
        temp: action.friends[1],
        man:  action.friends[2],
        dnside: action.friends[3]
      }
    case TEXT:
      return {
        ...state,
        temp: action.film,
        name: action.name
      }
    case GET_PERSON:
      return {
        ...state,
        year1: action.year1,
        year2: action.year2,
        name1: action.name1,
        name2: action.name2
      }
    case MODE:
      return{
        ...state,
        mode: action.mode
      }
    case NUMB:
      return{
        ...state,
        numb: action.numb
      } 
    case GET_FIND:
      return {
        ...state,
        temp: action.find,
        swch: 'lupa.png',
        year0: 0
      }
    case LANG:
      return{
        ...state,
        lang: action.lang
      }
    case LOGIN:
      return{
        ...state,
        logon: 0,
        login: 2,
        avat: action.payload
    }
    case UPD_COUNT:
      return {
        ...state,
        count: action.payload
    }
    case UPD_COUNTR:
      return {
        ...state,
        countr: action.payload
    }
    case GET_NEXT:
      return {
        ...state,
        post:    action.payload,
        numbs:   action.numbs,
        trigger: action.trigger
    }
    case GET_NEXTR:
      return {
        ...state,
        post:    action.payload,
        numba:   action.numbs,
        triggerr: action.trigger
    }
    case COLL:
      return {
        ...state,
        swch: 'coll.png',
        temp: action.film,
        post: action.film,
        film: action.fold,
        numb: action.numb,
        dnside: 2,
        logon: 2
    }
    case HALL:
      return {
        ...state,
        hall: action.stam,
        login: action.login,
        title: action.title,
        time: action.time,
        list: 0
    }
    case NEWU:
      return {
        ...state,
        entr: 0
    }
    default:
			return state
  }
}
