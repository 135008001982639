import React, { useEffect } from "react";
import {useSelector} from 'react-redux'

export const Hall = ({socket}) => {
  const hall = useSelector(state => state.post.hall)
  const time = useSelector(state => state.post.time)
  const title = useSelector(state => state.post.title)
  console.log(time)
  const dura = title[2]*60 - time
  const sour = 'https://globstam.space/images/' + hall + '.mp4'
  //autoplay="" controls="controls"
  const myvideo = <video id = "play" width="950" height="370" controls="controls">
                    <source src = {sour} type="video/mp4"></source>
                  </video>  

  useEffect(() => {
    socket.on('contrlsResponse', (data) => {
      const video = document.getElementById("play")
      if (data.text === 'p'){
        video.currentTime = dura
        video.play()
      }
      if (data.text === 's'){
        video.pause()
      }
    })
  }, [socket, dura])

  return (
    <>
      {myvideo}
    </>
  )
}

export default (Hall)